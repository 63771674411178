<template>
  <div class="check-page">
    <!-- 二维码过期-->
    <div
      v-if="pageStatus == 'failDate'"
      class="status-info pt65 mb40 flex flex-direction-column flex-align-center"
    >
      <img
        class="status-icon mb20"
        src="@/assets/img/qr_check/failed.svg"
        alt=""
      />
      <div class="status-text mb10">核销失败</div>
      <div class="status-hint">该二维码已过期</div>
    </div>
    <!-- 核销成功 -->
    <div
      v-else-if="pageStatus == 'success'"
      class="status-info pt65 mb40 flex flex-direction-column flex-align-center"
    >
      <img
        class="status-icon mb20"
        src="@/assets/img/qr_check/success.svg"
        alt=""
      />
      <div class="status-text mb10">核销成功</div>
    </div>
    <!-- 核销失败-未到时间或已被他人 核销 -->
    <div
      v-else-if="['failCheck', 'failMore', 'failOverdue'].includes(pageStatus)"
      class="bdr4 bgf pl15 pr15 pb15 mb15 mt15"
    >
      <div
        class="status-info pt45 mb40 flex flex-direction-column flex-align-center"
      >
        <img
          class="status-icon mb20"
          src="@/assets/img/qr_check/failed.svg"
          alt=""
        />
        <div class="status-text mb10">核销失败</div>
        <div v-if="pageStatus == 'failCheck'" class="status-hint">
          还未到可用时间
          <span class="txt-red">
            {{ props.statusNotify.use_start_time || '--' }}
          </span>
        </div>
        <div v-else-if="pageStatus == 'failOverdue'" class="status-hint">
          在
          <span class="txt-red">
            {{ props.statusNotify.expire_time || '--' }}
          </span>
          已过期
        </div>
        <div v-else-if="pageStatus == 'failMore'" class="status-hint tc">
          已被
          <span class="txt-red">
            {{ props.statusNotify.department || '--' }}
          </span>
          的
          <br />
          【
          <span class="txt-red">
            {{ props.statusNotify.member_name || '--' }}
          </span>
          】在
          <span class="txt-red">
            {{ props.statusNotify.verification_time || '--' }}
          </span>
          核销
        </div>
      </div>
      <div class="order-info mt60">
        <div class="info-row flex flex-align-start mb10">
          <div class="info-label cl4a mr20">订单编号</div>
          <div class="info-value cl17 flex flex-align-center">
            {{ props.orderDetail.order_no }}
            <img
              class="copy-icon ml10"
              src="@/assets/img/qr_check/copy.svg"
              @click="handleCopyText(props.orderDetail.order_no)"
              alt=""
            />
          </div>
        </div>
        <div class="info-row flex flex-align-start mb10">
          <div class="info-label cl4a mr20">商品图片</div>
          <div class="info-value">
            <img
              class="order-img"
              :src="props.orderDetail.order_goods_img"
              alt=""
            />
          </div>
        </div>
        <div class="info-row flex flex-align-start mb10">
          <div class="info-label cl4a mr20">商品名称</div>
          <div class="info-value cl17 flex flex-align-center">
            {{ props.orderDetail.goods_title }}
          </div>
        </div>
        <div class="info-row flex flex-align-start mb10">
          <div class="info-label cl4a mr20">兑换积分</div>
          <div class="info-value cl17 flex flex-align-center">
            {{ props.orderDetail.goods_score }} 积分
          </div>
        </div>
        <div class="info-row flex flex-align-start mb10">
          <div class="info-label cl4a mr20">兑换时间</div>
          <div class="info-value cl17 flex flex-align-center">
            {{ props.orderDetail.paid_time }}
          </div>
        </div>
        <div class="info-row flex flex-align-start mb10">
          <div class="info-label cl4a mr20">可用时间</div>
          <div class="info-value cl17 flex flex-align-center">
            {{ showTime(props.orderDetail) }}
          </div>
        </div>
      </div>
    </div>

    <div class="confirm-btn" @click="closeWindow">完成</div>
  </div>
</template>

<script setup>
// import { ref, onMounted } from 'vue'
import { WXRegister, setClipboardData } from '../../utils/nwechat'
import { useRouter, useRoute } from 'vue-router'
import { Toast } from 'vant'
const router = useRouter()
const route = useRoute()

const props = defineProps({
  orderDetail: {
    type: Object,
    default: () => {}
  },
  statusNotify: {
    type: Object,
    default: () => {}
  },
  pageStatus: {
    type: String,
    default: ''
  }
})

// 展示时间
const showTime = (detail) => {
  if (detail.goods_validity_time_type == 'absolute_time') {
    return `${detail.goods_validity_start_time} 至 ${detail.goods_validity_end_time}`
  } else {
    return `${detail.validity_start_time} 至 ${detail.validity_end_time}`
  }
}

// 复制文本
async function handleCopyText(text) {
  try {
    // iphone 存在 某一次复制不成功现象  errMsg: "setClipboardData:permission denied
    await WXRegister()
    await setClipboardData(text)
    Toast('复制成功')
  } catch (e) {
    console.log('复制失败error====>', e)
    Toast('复制失败，请重试或者自行选择进行复制')
  }
}

// 关闭应用
const closeWindow = () => {
  try {
    wx.closeWindow()
  } catch (e) {
    window.location.href = 'about:blank'
    window.close()

    window.opener = null
    window.open('', '_self')
    window.close()
  }
}

// onMounted(() => {
//   score_order_id.value = route.query.score_order_id || ''
//   time.value = route.query.time * 1000 || 0

//   // 页面最先判断二维码是否过期
//   if (time.value - new Date() < 0) {
//     pageStatus.value = 'failDate'
//   }
// })
</script>

<style lang="less">
.check-page {
  padding: 0 15px;

  .status-info {
    .status-icon {
      display: block;
      width: 70px;
      height: 70px;
    }

    .status-text {
      font-size: 18px;
      color: #171717;
      line-height: 26px;
    }

    .status-hint {
      font-size: 14px;
      line-height: 20px;
      color: #666;
    }
  }

  .info-row {
    font-size: 12px;
    line-height: 16px;

    .info-label {
      min-width: 48px;
    }
    .info-value {
      font-weight: 500;
    }
    .copy-icon {
      display: block;
      width: 16px;
      height: 16px;
    }

    .order-img {
      display: block;
      width: 60px;
      height: 60px;
    }
  }
  .confirm-btn {
    width: 100%;
    line-height: 44px;
    color: #fff;
    background-color: #1773fa;
    font-size: 14px;
    text-align: center;
    border-radius: 5px;
  }
}
</style>
